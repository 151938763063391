import React from 'react';
import PropTypes from 'prop-types';
import AnimatedElements, {AnimatedElementsPropTypeThemes} from "../components/AnimatedElements";

const Block = ({ children, config = {}, className, classNameInner }) => {
    const classes = React.useMemo(() => {
        let cls = 'Block';
        cls += config.animation ? ' Block_theme_withAnimatedElements' : '';
        if (!config.underPrev) {
            cls += config.baseBorder ? ' Block_theme_withBaseBorder' : '';
        }
        cls += config.underPrev ? ' Block_theme_underPrev' : '';
        cls += config.symmetric ? ' Block_theme_symmetric' : '';
        cls += (config || {}).animation === '5' ? ' Block_theme_withoutRelative' : '';
        cls += className ? ` ${className}` : '';

        return cls;
    }, [config, className]);

    const classesInner = React.useMemo(() => {
        let cls = 'Block-Inner'

        cls += classNameInner ? ` ${classNameInner}` : '';

        return cls;
    }, [classNameInner])
    return (
        <div className={classes}>
            {config && config.animation && <AnimatedElements theme={config.animation} />}
            <div className={classesInner}>
                {children}
            </div>
        </div>
    )
};

export const BlockPropTypeConfig = PropTypes.shape({
    animation: AnimatedElementsPropTypeThemes,
    baseBorder: PropTypes.bool,
    underPrev: PropTypes.bool,
    symmetric: PropTypes.bool
});

Block.propTypes = {
    config: BlockPropTypeConfig,
    className: PropTypes.string,
    classNameInner: PropTypes.string,
};

export default Block;
