import React from 'react';
import Block from "../../blocks/Block";
import BlockSection from "../../blocks/BlockSection";
import Title from "../typography/Title";

export const ConsentProcessingBlock = ({ title, text }) => {
    return (
        <Block className="Block_theme_about" config={{
            baseBorder: true,
            animation: '3'
        }}>
            <div className="container">
                <BlockSection>
                    <Title className="Block-Title" level="h5" headerStyleNumb="6">{title}</Title>
                    <div className="Block-Text text6" dangerouslySetInnerHTML={{ __html: text }}/>
                </BlockSection>
            </div>
        </Block>
    );
};
