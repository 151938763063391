import React from 'react';
import PropTypes from 'prop-types';
import Heading from "./Heading";

const Title = ({ level = 'h2', headerStyleNumb = '2', color, children, className, innerHTML }) => {

    const child = React.useMemo(() => {
        if (color === 'first' || color === 'second') {
            if (innerHTML) {
                return `<span class="textDecorative_gradient_${color}">${innerHTML}</span>`;
            }
            return <span className={`textDecorative_gradient_${color}`}>{children}</span>;
        }
        if (color === 'grey') {
            if (innerHTML) {
                return `<span class="textDecorative_color_${color}">${innerHTML}</span>`
            }
            return <span className={`textDecorative_color_${color}`}>{children}</span>;
        }
        if (innerHTML) {
            return innerHTML;
        }
        return children;
    }, [innerHTML, children, color]);

    const classes = React.useMemo(() => {
        let cls = `header${headerStyleNumb}`;
        cls += className ? ' ' + className : '';

        return cls;
    }, [className, headerStyleNumb]);

    return (
        <React.Fragment>
            {
                innerHTML
                    ? <Heading type={level} className={classes} dangerouslySetInnerHTML={{__html: child }} />
                    : <Heading type={level} className={classes}>{child}</Heading>
            }
        </React.Fragment>
    )
};

export const TitlePropColor = PropTypes.oneOf(['first', 'second', 'grey', 'none']);

Title.propTypes = {
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    headerStyleNumb: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']),
    color: TitlePropColor,
    className: PropTypes.string,
    innerHTML: PropTypes.string
};

export default Title;
