import React from "react";
import Layout from "../../layout/Layout";
import { ConsentProcessingBlock } from "../../components/ConsentProcessingPd";
import SEO from "../../components/seo";

import enMessages from '../../i18n/business/consent-processing-pd/en.js';
import ruMessages from '../../i18n/business/consent-processing-pd/ru.js';

const messages = {
  en: enMessages,
  ru: ruMessages
}

const ConsentProcessingPdPage = ({ pathContext: { locale } }) => {
  return (
    <Layout locale={locale}>
      <SEO
        title={messages[locale].seo.title}
        description={messages[locale].seo.description}
      />
      <ConsentProcessingBlock
        title={messages[locale].consentProcessingBlock.title}
        text={messages[locale].consentProcessingBlock.text}
      />
    </Layout>
  )
};

export default ConsentProcessingPdPage;
