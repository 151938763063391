import React from "react";
import PropTypes from "prop-types";

const BlockSection = ({ children, moreMargin, id, className }) => {
    const classes = React.useMemo(() => {
        let cls = 'Block-Section';
        cls += moreMargin ? ' Block-Section_moreMargin' : '';
        cls += className ? ` ${className}` : '';

        return cls;
    }, [moreMargin]);

    return (
        <div className={classes} id={id}>{children}</div>
    );
};

BlockSection.propTypes = {
    moreMargin: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string
};

export default BlockSection;
