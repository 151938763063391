export default {
  "seo": {
      "title": "Consent to personal data processing",
      "description": "Bank 131 – Russia’s newest digital bank. API-based compliant payment solution for Russia, mass payouts, payouts to the self-employed, online payments, and banking as a service."
  },
  "consentProcessingBlock": {
      "title": "Consent to personal data processing",
      "text":
      "<p>I, in accordance with the requirements of the Federal Law No. 152-FZ «On Personal Data» dated 27/07/2006, agree that Bank 131 Joint-Stock Company, legal address: 420012, 38 Nekrasova street, Kazan, Republic of Tatarstan, Russian Federation (hereinafter referred to as the Bank) processes my personal data using automation tools and (or) without using such tools, including collection, recording, systematization, accumulation, storage, improvement (updating, amendment), extract, use, depersonalization, blocking, deletion, destruction of personal data, transfer (provision, access), and assignment of processing to third parties, which list is posted on the website of the Bank at <a href='https://131.ru/legal/pdProtection/'>www.131.ru/legal/pdProtection</a> («List of third parties who process personal data of Clients on the basis of agreements concluded by them with the Bank, consents of Clients») (hereinafter referred to as «Third Parties»)).</p>" +
      
      "<p>The list of personal data transferred to the Bank for processing: last name, first name, patronymic, email, telephone, as well as name of legal entity / individual entrepreneur and INN (Taxpayer Identification Number), website and description of activities of legal entity / individual entrepreneur, if the application is submitted on behalf of the legal entity / individual entrepreneur), as well as other information that has become known to the Bank and (or) provided by me to the Bank during considering my application to:</p>" +

      "<ul>" +

      "<li>communicate with me to provide information about the services of the Bank, procedure for accepting me for banking servicing;</li>" +

      "<li>consider the possibility of the Bank to conclude agreements with me, provide information about the results of consideration and further execution of agreements, if concluded;</li>" +

      "<li>agree on the terms of agreements and contracts with the Bank;</li>" +

      "<li>verify the accuracy of the data provided by me;</li>" +

      "<li>provide the Bank with information about the execution of agreements and transactions carried out on accounts;</li>" +

      "<li>communicate with me for the above purposes, including with participation of Third Parties.</li>" +

      "</ul>" +

      "<p>This consent to process personal data is valid from the moment of its transfer to the Bank (clicking «Submit» button) and for the entire term of contractual obligations (in case of conclusion of the relevant contract / agreement), as well as for 5 (five) years after the date of termination of contractual obligations between me and the Bank. In the absence of contractual obligations between me and the Bank, this consent is valid until the purposes of personal data processing are achieved. </p>" +

      "<p>Consent may be revoked in accordance with paragraph 2 of Article 9 of the Federal Law No. 152-FZ «On Personal Data» dated 27/07/2006 by submitting an application to the Bank in simple written form, or by sending this application by registered mail by Russian Post to the legal address of the Bank, while the Bank has the right to continue processing personal data, if there are grounds specified in paragraphs 2-11 of Part 1 of Article 6 of the Federal Law No. 152-FZ «On Personal Data» dated 27/07/2006.</p>"
  }
}
