export default {
  "seo": {
      "title": "Согласие на обработку персональных данных",
      "description": "Банк 131 — цифровой банк, платежные решения для бизнеса: прием платежей онлайн, выплаты и расчеты с физическими лицами и самозанятыми. Лицензия ЦБ РФ, официальный партнер ФНС."
  },
  "consentProcessingBlock": {
      "title": "Согласие на обработку персональных данных",
      "text":
      "<p>Я, в соответствии с требованиями Федерального закона от 27.07.2006г. № 152-ФЗ «О персональных данных», выражаю свое согласие Акционерному обществу «Банк 131», юридический адрес: 420012, Российская Федерация, Республика Татарстан, г. Казань, ул. Некрасова, д. 38 (далее – Банк) на обработку моих персональных данных с использованием средств автоматизации и (или) без использования таких средств, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных, передачу (предоставление, доступ), и поручение обработки третьим лицам, перечень которых размещен на сайте Банка по ссылке <a href='https://131.ru/legal/pdProtection/'>www.131.ru/legal/pdProtection</a> («Перечень третьих лиц, которые осуществляют обработку персональных данных клиентов на основании заключенных ими с АО «Банк 131» договоров, согласий клиентов»)(далее – «Третьи лица»)).</p>" +
      
      "<p>Перечень персональных данных, передаваемых Банку на обработку: фамилия, имя, отчество, адрес электронной почты, телефон, а также наименование ЮЛ/ИП, ИНН, веб-сайт и описание деятельности ЮЛ/ИП, в случае подачи заявки от имени ЮЛ/ИП), а также иные сведения, ставшие известными Банку и (или) предоставленные мной Банку в процессе рассмотрения моей заявки в целях:</p>" +

      "<ul>" +

      "<li>осуществления связи со мной для предоставления информации об услугах Банка, о порядке принятия на банковское обслуживание;</li>" +

      "<li>рассмотрения возможности заключения Банком со мной договоров, предоставления информации о результатах рассмотрения и дальнейшего исполнения договоров, в случае заключения;</li>" +

      "<li>согласования условий договоров и соглашений с Банком;</li>" +

      "<li>осуществления проверки достоверности представленных мной данных;</li>" +

      "<li>предоставления Банком информации об исполнении договоров и проводимых операциях по счетам;</li>" +

      "<li>осуществления контактов со мной в указанных выше целях, в том числе с участием Третьих лиц.</li>" +

      "</ul>" +

      "<p>Настоящее согласие на обработку персональных данных действует с момента его передачи в Банк (нажатие кнопки «Отправить») и в течение всего срока действия договорных обязательств (в случае заключения соответствующего договора/соглашения), а также в течение 5 (пяти) лет после даты прекращения договорных обязательств между мной и Банком. При отсутствии между мной и Банком договорных обязательств, настоящее согласие действует до достижения целей обработки персональных данных.</p>" +

      "<p>Согласие может быть отозвано в соответствии с п. 2. ст. 9 Федерального закона от 27.07.2006г. № 152-ФЗ «О персональных данных» путем предоставления в Банк заявления в простой письменной форме, либо путем направления этого заявления заказным письмом почтой Российской Федерации на юридический адрес Банка, при этом Банк вправе продолжить обработку персональных данных, при наличии оснований, указанных в п. 2-11 ч. 1 ст. 6 Федерального закона от 27.07.2006г. № 152-ФЗ «О персональных данных».</p>"
  }
}
